import React, { useCallback, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ShareIcon from '../../components/ShareIcon';
import { ExpandableWrapper, Spacer } from '../../styles';
import {
  SubTitle,
  StepTabWrapper,
  StepTab,
  StepLabelWrapper,
  StepLabel,
  StepDesc,
  ShareDesc,
  ShareIconsWrapper,
} from './styled';

import GA from '../../services/ga';

const FB_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID as string;
const HOST = process.env.REACT_APP_HOST as string;

enum Tutorial {
  WHATSAPP,
  FACEBOOK,
  OTHERS,
}

interface TutorialItemProps {
  referralLink: string;
}

const TutorialTitleWrapper = styled.div`
  margin: 24px 20px;
  position: relative;
`;

const ExpandArrow = styled.img<{ open: boolean }>`
  position: absolute;
  right: 0;
  top: 4px;

  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'none')};
`;

const TutorialItem = styled.div`
  color: #4f4f4f;
  margin: 0 20px;
  position: relative;

  img {
    width: 100%;
    margin-top: 12px;
  }

  p {
    font-size: 22px;
    line-height: 31px;
    white-space: pre-line;
  }
`;

const TutorialSection: React.FC<TutorialItemProps> = ({
  referralLink,
}) => {
  const { t } = useTranslation('common');

  const [show, setShow] = useState<boolean>(false);
  const [currentSection, setCurrentSection] = useState<Tutorial>(
    Tutorial.WHATSAPP,
  );

  const onExpandTutorial = () => {
    // current state is false => going to show
    // current state is true => going to hide
    GA.trackEvent('click', 'tutorial', !show ? 'show' : 'hide');
    setShow(!show);
  };

  const onTabClick = (section: Tutorial) => {
    if (section === currentSection) {
      return;
    }

    GA.trackEvent('click', 'tutorial', Tutorial[section].toLowerCase());
    setCurrentSection(section);
  };

  const handleShare = useCallback(
    async (type: string) => {
      let msg = t('referral.share.message');

      switch (type) {
        case 'wts':
          msg = `${msg}%0a${referralLink}`;
          if (isIOS) {
            window.location.href = `https://api.whatsapp.com/send?text=${msg}`;
          } else {
            window.open(`https://api.whatsapp.com/send?text=${msg}`);
          }
          GA.trackEvent('click', 'tutorial', 'share_whatsapp');
          break;
        case 'fb':
          window.open(
            `https://www.facebook.com/dialog/share?app_id=${FB_APP_ID}&display=popup&href=${referralLink}&redirect_uri=${HOST}`,
            'WindowName',
            'popup,width=400,height=300',
          );
          GA.trackEvent('click', 'tutorial', 'share_facebook');
          break;
        default:
          if (navigator.share) {
            navigator.share({
              text: `${t('referral.share.message')}`,
              url: referralLink,
            });
            GA.trackEvent('click', 'tutorial', 'share_others');
          }
      }
    },
    [referralLink],
  );

  return (
    <>
      <TutorialTitleWrapper onClick={onExpandTutorial}>
        <SubTitle>{t('referral.tutorial.title')}</SubTitle>
        <ExpandArrow src="logo_expand_arrow.png" open={show} />
      </TutorialTitleWrapper>

      <ExpandableWrapper expanded={show}>
        <StepTabWrapper>
          <StepTab
            selected={currentSection === Tutorial.WHATSAPP}
            onClick={() => onTabClick(Tutorial.WHATSAPP)}
          >
            <StepLabelWrapper>
              <StepLabel>
                {t('referral.tutorial.whatsapp.title')}
              </StepLabel>
              <StepDesc>{t('referral.tutorial.whatsapp.desc')}</StepDesc>
            </StepLabelWrapper>
          </StepTab>
          <StepTab
            selected={currentSection === Tutorial.FACEBOOK}
            onClick={() => onTabClick(Tutorial.FACEBOOK)}
          >
            <StepLabelWrapper>
              <StepLabel>
                {t('referral.tutorial.facebook.title')}
              </StepLabel>
              <StepDesc>{t('referral.tutorial.facebook.desc')}</StepDesc>
            </StepLabelWrapper>
          </StepTab>

          {isMobile && (
            <StepTab
              selected={currentSection === Tutorial.OTHERS}
              onClick={() => onTabClick(Tutorial.OTHERS)}
            >
              <StepLabelWrapper>
                <StepLabel>
                  {t('referral.tutorial.others.title')}
                </StepLabel>
                <StepDesc>{t('referral.tutorial.others.desc')}</StepDesc>
              </StepLabelWrapper>
            </StepTab>
          )}
        </StepTabWrapper>

        <Spacer height={16} />

        {currentSection === Tutorial.WHATSAPP && (
          <>
            <TutorialItem>
              <p>{t('referral.tutorial.whatsapp.steps.one')}</p>
              <img src="tutorial_whatsapp_1.jpg" alt="" />
            </TutorialItem>

            <Spacer height={20} />

            <TutorialItem>
              <p>{t('referral.tutorial.whatsapp.steps.two')}</p>
              <img src="tutorial_whatsapp_2.jpg" alt="" />
            </TutorialItem>

            <Spacer height={20} />

            <TutorialItem>
              <p>{t('referral.tutorial.whatsapp.steps.three')}</p>
              <img src="tutorial_whatsapp_3_v2.jpg" alt="" />
            </TutorialItem>
          </>
        )}

        {currentSection === Tutorial.FACEBOOK && (
          <>
            <TutorialItem>
              <p>{t('referral.tutorial.facebook.steps.one')}</p>
              <img src="tutorial_facebook_1.jpg" alt="" />
            </TutorialItem>

            <Spacer height={20} />

            <TutorialItem>
              <p>{t('referral.tutorial.facebook.steps.two')}</p>
              <img src="tutorial_facebook_2_v2.jpg" alt="" />
            </TutorialItem>
          </>
        )}

        {currentSection === Tutorial.OTHERS && (
          <>
            <TutorialItem>
              <p>{t('referral.tutorial.others.steps.one')}</p>
              <img src="tutorial_others_1.jpg" alt="" />
            </TutorialItem>

            <Spacer height={20} />

            <TutorialItem>
              <p>{t('referral.tutorial.others.steps.two')}</p>
              <img src="tutorial_others_2_v2.jpg" alt="" />
            </TutorialItem>

            <Spacer height={20} />

            <TutorialItem>
              <p>{t('referral.tutorial.others.steps.three')}</p>
            </TutorialItem>
          </>
        )}

        <Spacer height={30} />
        <ShareDesc>
          {t(
            `referral.share.desc.${isMobile ? 'mobile' : 'desktop'}`,
          ).substring(1)}
        </ShareDesc>
        <ShareIconsWrapper>
          <ShareIcon
            src="logo_share_wts.png"
            onClick={() => handleShare('wts')}
          />
          <ShareIcon
            src="logo_share_fb.png"
            onClick={() => handleShare('fb')}
          />
          {isMobile && (
            <ShareIcon
              src="logo_share_others.png"
              onClick={() => handleShare('others')}
            />
          )}
        </ShareIconsWrapper>

        <Spacer height={23} />
      </ExpandableWrapper>
    </>
  );
};

export default TutorialSection;
