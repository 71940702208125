const GA_ID = process.env.REACT_APP_GA_ID as string;

const trackPageView = (path: string): void => {
  if (path.includes('facebook')) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/dot-notation
  window['gtag']('event', 'page_view', {
    page_title: document.title,
    page_path: path,
    send_to: GA_ID,
  });
};

const trackEvent = (action: string, category: string, label: string, value?: string): void => {
  const payload = {
    event_category: category,
    event_label: label,
    send_to: GA_ID,
  };

  if (value) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    payload['value'] = value;
  }

  // eslint-disable-next-line @typescript-eslint/dot-notation
  window['gtag']('event', action, payload);
};

export default {
  trackPageView,
  trackEvent,
};
