import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import GA from '../../services/ga';
import AboutCard from './AboutCard';

const AboutReferral: React.FC = () => {
  const onClick = useCallback(() => {
    GA.trackEvent('click', 'banner', 'referral');
  }, []);

  return (
    <Link to="/referral" onClick={onClick}>
      <AboutCard
        image="/about_referral.jpg"
        title="about.referral.title"
        desc="about.referral.desc"
      />
    </Link>
  );
};

export default AboutReferral;
