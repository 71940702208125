import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import GA from '../../services/ga';
import AboutCard from './AboutCard';

const AboutProduct: React.FC = () => {
  const onClick = useCallback(() => {
    GA.trackEvent('click', 'banner', 'product');
  }, []);

  return (
    <Link to="/product" onClick={onClick}>
      <AboutCard
        image="/about_product.jpg"
        title="about.product.title"
        desc="about.product.desc"
      />
    </Link>
  );
};

export default AboutProduct;
