import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div<WrapperProps>`
  background-image: url(${(props: WrapperProps) => props.image});
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 38%;
  cursor: pointer;
`;

const CardContentWrapper = styled.div`
  background-color: #d3f3e1;
  display: flex;
  color: #008141;
  font-size: 24px;
  line-height: 170%;
  padding: 14px 24px 8px 24px;
`;

const CardTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  line-height: 37px;
`;

const CardArrow = styled.img`
  margin-left: auto;
`;

interface WrapperProps {
  image: string;
}

interface AboutCardProps {
  image: string;
  title: string;
  desc: string;
  className?: string;
}

const AboutCard: React.FC<AboutCardProps> = ({
  image,
  title,
  desc,
  className,
}: AboutCardProps) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper className={className} image={image}>
      <CardContentWrapper>
        <div>
          <CardTitle>{t(title)}</CardTitle>
          <div>{t(desc)}</div>
        </div>
        <CardArrow src="/about_arrow.svg" />
      </CardContentWrapper>
    </Wrapper>
  );
};

AboutCard.defaultProps = {
  className: '',
};

export default AboutCard;
