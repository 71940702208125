import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #6ec590;
  position: relative;
  padding: 10px 20px;
  height: 37px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 65%;
  height: 100%;
`;

const Logo = styled.img`
  width: auto;
  height: 100%;
`;

const LogoText = styled.img`
  margin-left: 8px;
  width: auto;
  height: 47%;
`;

const MenuLogo = styled.img`
  position: absolute;
  height: calc(100% - 20px);
  width: auto;
  
  cursor: pointer;
`;

interface HeaderProps {
  onShowMenu: (isShow: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ onShowMenu }: HeaderProps) => (
  <Wrapper>
    <MenuLogo src="/logo_menu.svg" onClick={() => onShowMenu(true)} />
    <LogoWrapper>
      <Logo src="/logo_polident.png" />
      <LogoText src="/logo_polident_txt.png" />
    </LogoWrapper>
  </Wrapper>
);

export default Header;
