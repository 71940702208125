import styled from 'styled-components';

const FormErrorLabel = styled.div`
  color: #f44336;
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;
`;

export default FormErrorLabel;
