import styled, { css } from 'styled-components';

export const StyledButton = css`
  background: #26af5d;
  border-radius: 2px;
  border-width: 0;
  box-shadow: 0 4px 0 #008040;
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;
  line-height: 37px;
  height: 56px;
  width: 100%;

  cursor: pointer;
`;

export const RegisterButton = styled.button`
  ${StyledButton};
  margin: 32px 20px 50px 20px;
  width: calc(100% - 40px);
`;

export const ResponsiveContainer = styled.div`
  max-width: 512px;
  margin: auto;
`;

export default { ResponsiveContainer, RegisterButton, StyledButton };
