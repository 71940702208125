import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { isAndroid, isIOS } from 'react-device-detect';

import GA from '../../services/ga';

const FacebookPage: React.FC = () => {
  const url = process.env.REACT_APP_FACEBOOK_URL as string;
  const pageId = process.env.REACT_APP_FACEBOOK_PAGE_ID as string;

  useEffect(() => {
    GA.trackEvent('click', 'share', 'facebook');
  }, []);

  if (isIOS) {
    setTimeout(() => {
      window.location.replace(url);
    }, 25);
    window.location.replace(`fb://profile/${pageId}`);
    return (null);
  }

  if (isAndroid) {
    setTimeout(() => {
      window.location.replace(url);
    }, 25);
    window.location.replace(`fb://page/${pageId}`);
    return (null);
  }

  window.location.replace(url);
  return (null);
};

export default withRouter(FacebookPage);
