import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #26af5d;
  display: flex;
  align-items: center;
  padding: 10px 30px;
`;

const Menu = styled.div`
  background-color: #d3f3e1;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  width: 100%;
`;

const MenuItem = styled.div`
  align-items: center;
  color: #008141;
  font-size: 12px;
  line-height: 14px;
  padding: 0 6px;
  text-align: center;

  cursor: pointer;

  &:not(:first-child) {
    border-left: 1px solid #000000;
  }
`;

const GskIcon = styled.img`
  margin-right: 30px;
  height: 32px;
  width: 38px;
`;

const CopyRight = styled.div`
  color: #ffffff;
  font-size: 8px;
  line-height: 9px;
  white-space: pre-line;
`;

const Footer: React.FC = () => {
  const [t] = useTranslation('common');

  const menuList = useMemo<string[]>(
    () => t('footer.menus', { returnObjects: true }),
    [t],
  );

  const handleMenuClick = (itemIndex) => {
    let href;
    switch (itemIndex) {
      case 0:
        href = 'https://www.mydenturecare.com/zh-hk/contact/';
        break;
      case 1:
        href = 'https://www.mydenturecare.com/zh-hk/about-us/';
        break;
      case 2:
        href = 'https://terms.gsk.com/zh-hk/consumer-healthcare/default/';
        break;
      case 3:
        href = 'https://privacy.gsk.com/zh-hk/consumer-healthcare/default/';
        break;
      case 4:
        href = 'https://cookies.gsk.com/zh-hk/consumer-healthcare/default/';
        break;
      default:
        // not a valid menu click
        href = null;
    }

    if (href) {
      window.location.href = href;
    }
  };

  return (
    <>
      <Menu>
        {menuList.map((item, index) => (
          <MenuItem key={item} onClick={() => handleMenuClick(index)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
      <Wrapper>
        <GskIcon src="/logo_gsk.png" />
        <CopyRight>{t('footer.content')}</CopyRight>
      </Wrapper>
    </>
  );
};

export default Footer;
