const tagPages = (): void => {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  window['gtag']('event', 'conversion', {
    allow_custom_scripts: true,
    send_to: 'DC-8740964/polid0/gsk_h00f+standard',
    activity_group: 'Polident',
    activity: '10995641',
  });
};

const tagForm = (): void => {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  window['gtag']('event', 'conversion', {
    allow_custom_scripts: true,
    send_to: 'DC-8740964/polid0/gsk_h00g+standard',
    activity_group: 'Polident',
    activity: '10996259',
  });
};

const tagThankyou = (): void => {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  window['gtag']('event', 'conversion', {
    allow_custom_scripts: true,
    send_to: 'DC-8740964/polid0/gsk_h00h+standard',
    activity_group: 'Polident',
    activity: '10995644',
  });
};

export default {
  tagForm,
  tagPages,
  tagThankyou,
};
