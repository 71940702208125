import styled from 'styled-components';

import FormLabel from './FormLabel';

const DisclaimerWrapper = styled.div`
  color: #828282;
  font-size: 18px;
  line-height: 170%;
  margin: 28px 20px 0 20px;

  ${FormLabel} {
    display: flex;
    color: #828282;
    font-size: 18px;
    line-height: 170%;
    margin-top: 14px;
    margin-left: -4px;
  }

  a {
    color: #1778f2;
  }

  span {
    white-space: pre-line;
  }
`;

export default DisclaimerWrapper;
