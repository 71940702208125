import React, { useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import AboutBenefit from '../../components/about/AboutBenefit';
import AboutReferral from '../../components/about/AboutReferral';
import { StyledButton, RegisterButton } from '../../components/common';
import SocialMedia from '../../components/SocialMedia';
import { Spacer } from '../../styles';

import GA from '../../services/ga';

const Wrapper = styled.div`
  padding-top: 30px;

  h1 {
    margin: 0 20px;
    white-space: pre-line;
  }
`;

const Banner = styled.img`
  margin-top: 12px;
  width: 100%;
`;

const KnowMoreButton = styled.button`
  ${StyledButton};
  background-color: #abc738;
  box-shadow: 0 4px 0 #78963b;
  margin: 32px 20px 0 20px;
  width: calc(100% - 40px);
`;

const SectionWrapper = styled.div`
  margin: 32px 0;

  p {
    color: #828282;
    font-size: 22px;
    line-height: 30px;
    margin: 8px 20px 0 20px;
    padding: 0;
    position: relative;
    white-space: pre-line;
  }

  .high-light {
    color: #008141;
    font-size: 22px;
    font-weight: bold;
    line-height: 140%;
  }

  .bullet::before {
    content: '・';
    position: absolute;
    left: 0;
  }

  .tick-content::before {
    content: '✓';
    position: absolute;
    left: 4px;
  }

  .sub-content {
    padding-left: 42px !important;
  }

  .sub-content.bullet::before {
    position: absolute;
    left: 20px;
  }

  .bullet,
  .tick-content {
    display: inline-block;
    padding-left: 24px;
  }
`;

const SectionFooter = styled.div`
  color: #828282;
  font-size: 12px;
  line-height: 170%;
  margin: 0 20px;
  white-space: pre-line;
`;

interface Section {
  title: string;
  content: string;
}

const ProductPage: React.FC = () => {
  const { t } = useTranslation('common');

  const sectionA = useMemo<Section[]>(
    () => t('product.sections.a', { returnObjects: true }),
    [t],
  );

  const sectionB = useMemo<Section[]>(
    () => t('product.sections.b', { returnObjects: true }),
    [t],
  );

  const navigateToDetails = (events) => {
    events.preventDefault();
    GA.trackEvent('click', 'open', 'product_details');
    window.open(process.env.REACT_APP_MAIN_SITE_URL as string);
  };

  return (
    <Wrapper>
      <h1>{t('product.title')}</h1>
      <Banner src="/banner_product_a.jpg" />
      {sectionA.map((section: Section) => (
        <SectionWrapper key={uuidv4()}>
          <h1 dangerouslySetInnerHTML={{ __html: section.title }} />
          <p dangerouslySetInnerHTML={{ __html: section.content }} />
        </SectionWrapper>
      ))}
      <SectionFooter>{t('product.sectionFooterA')}</SectionFooter>

      <Banner src="/banner_product_b.png" />
      {sectionB.map((section: Section) => (
        <SectionWrapper key={uuidv4()}>
          <h1 dangerouslySetInnerHTML={{ __html: section.title }} />
          <p dangerouslySetInnerHTML={{ __html: section.content }} />
        </SectionWrapper>
      ))}
      <SectionFooter>{t('product.sectionFooterB')}</SectionFooter>

      <KnowMoreButton onClick={navigateToDetails}>
        {t('buttons.knowMore')}
      </KnowMoreButton>
      <Link to="/">
        <RegisterButton>{t('buttons.register')}</RegisterButton>
      </Link>

      <AboutReferral />
      <Spacer height={12} />
      <AboutBenefit />

      <SocialMedia />
    </Wrapper>
  );
};

export default withRouter(ProductPage);
