import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: white;
    font-family: 'Noto Sans TC', Open-Sans, Helvetica, Sans-Serif;
  }

  textarea,
  input.text,
  input[type="text"],
  input[type="button"],
  input[type="submit"],
  .input-checkbox {
    -webkit-appearance: none;
    border-radius: 2px;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }

  h1 {
    color: #008141;
    font-size: 34px;
    font-weight: bold;
    line-height: 40px;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  p {
    margin: 0;
  }
`;

export default GlobalStyle;
