import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const Wrapper = styled.div`
  background-color: #ffffff;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99;
`;

const Header = styled.div`
  background-color: #26af5d;
  display: flex;
  align-items: center;
  height: 57px;

  img {
    display: block;
    margin-left: 20px;
    height: 39px;
    width: 39px;

    cursor: pointer;
  }
`;

const MenuItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px 22px 12px 31px;

  cursor: pointer;
`;

const MenuItemContent = styled.div`
  color: #707070;
  font-size: 32px;
  font-weight: bold;
  line-height: 37px;
`;

const MenuArrow = styled.img`
  margin-left: auto;
`;

interface SlideMenuProps {
  onShowMenu: (isShow: boolean) => void;
}

const SlideMenu: React.FC<SlideMenuProps> = ({
  onShowMenu,
}: SlideMenuProps) => {
  const history = useHistory();
  const { t } = useTranslation('common');

  const menuItems = useMemo<string[]>(
    () => t('menu', { returnObjects: true }),
    [t],
  );

  const handleMenuClick = (index) => {
    switch (index) {
      case 0:
        history.push('/');
        break;
      case 1:
        history.push('/product');
        break;
      case 2:
        history.push('/benefits');
        break;
      case 3:
        history.push('/referral');
        break;
      default:
        history.push('/');
    }

    onShowMenu(false);
  };

  return (
    <Wrapper>
      <Header onClick={() => onShowMenu(false)}>
        <img src="/logo_close.svg" alt="" />
      </Header>
      {menuItems.map((item, index) => (
        <MenuItemWrapper
          key={uuidv4()}
          onClick={() => handleMenuClick(index)}
        >
          <MenuItemContent>{item}</MenuItemContent>
          <MenuArrow src="about_arrow.svg" />
        </MenuItemWrapper>
      ))}
    </Wrapper>
  );
};

export default SlideMenu;
