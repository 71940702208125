import React from 'react';
import styled from 'styled-components';

const FormLabel = styled.label`
  color: #4f4f4f;
  display: block;
  font-size: 22px;
  line-height: 140%;

  :not(:first-child) {
    margin-top: 15px;
  }

  &.error {
    border: 1px solid #f44336;
  }
`;

export default FormLabel;
