import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import GA from '../../services/ga';
import AboutCard from './AboutCard';

const AboutBenefit: React.FC = () => {
  const onClick = useCallback(() => {
    GA.trackEvent('click', 'banner', 'benefit');
  }, []);

  return (
    <Link to="/benefits" onClick={onClick}>
      <AboutCard
        image="/about_gift.jpg"
        title="about.gift.title"
        desc="about.gift.desc"
      />
    </Link>
  );
};

export default AboutBenefit;
