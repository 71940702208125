import axios, { AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { IRegisterForm } from '../../../../shared/interfaces';

import AboutBenefit from '../../components/about/AboutBenefit';
import AboutProduct from '../../components/about/AboutProduct';
import AboutReferral from '../../components/about/AboutReferral';
import Banner from '../../components/Banner';
import DescriptionComponent from '../../components/DescriptionComponent';
import PrivacyModal from '../../modals/PrivacyModal';
import SocialMedia from '../../components/SocialMedia';
import { Spacer } from '../../styles';

import Floodlight from '../../services/floodlight';
import GA from '../../services/ga';

import RegisterForm from './Form';

const Wrapper = styled.div``;

const customStyles = {
  content: {
    top: '10px',
    left: '10px',
    right: '10px',
    bottom: '10px',
    padding: '0',
    overflow: 'hidden',
  },
};

const RegistrationPage: React.FC = () => {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  // disabled
  // const isStaff = location.pathname.includes('signup');

  useEffect(() => {
    if (location.hash && location.hash.includes('privacy')) {
      setShowPrivacy(true);
    }
  }, [location]);

  useEffect(() => {
    if (showPrivacy) {
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'auto';
  }, [showPrivacy]);

  useEffect(() => {
    Floodlight.tagForm();
  }, []);

  const handleSubmit = async (formValues: IRegisterForm) => {
    const {
      name,
      gender,
      phone,
      age,
      birthdayMonth,
      address,
      denturePosition,
      dentureType,
      period,
      isUser,
      source,
      privacyPolicy,
    } = formValues;

    // order formValues
    const data = {
      name,
      gender,
      phone,
      age,
      birthdayMonth,
      address,
      denturePosition,
      dentureType,
      period,
      isUser,
      privacyPolicy,
      dataSource: 'CRM site',
      referrer: query.get('referral_code') || '',
      source,
      // disabled
      // dataSource: isStaff ? 'CRM hotline' : 'CRM site',
    };
    const config: AxiosRequestConfig = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL as string}/register`,
      data,
    };

    try {
      await axios(config);
      GA.trackEvent('click', 'submit', 'form');
    } catch (e) {
      console.error(e);
      GA.trackEvent('error', 'submit', 'form', JSON.stringify(data));
    }
    history.push('/thankyou');
  };

  const handleShowPrivacy = (isShow: boolean) => {
    setShowPrivacy(isShow);

    if (!isShow && location.hash.includes('privacy')) {
      history.replace('/');
    }

    if (isShow) {
      GA.trackEvent('click', 'open', 'tnc');
    }
  };

  return (
    <Wrapper>
      <Banner src="/banner_form.jpg" />
      <DescriptionComponent
        title="register.header"
        desc="register.content"
        // disabled
        // remark={isStaff ? 'register.staffOnly' : undefined}
      />
      <RegisterForm
        onSubmit={handleSubmit}
        onShowPrivacy={handleShowPrivacy}
      />
      <Modal isOpen={showPrivacy} style={customStyles} ariaHideApp={false}>
        <PrivacyModal onClose={() => handleShowPrivacy(false)} />
      </Modal>

      <Spacer height={38} />
      <AboutReferral />
      <Spacer height={12} />
      <AboutBenefit />
      <Spacer height={12} />
      <AboutProduct />

      <SocialMedia />
    </Wrapper>
  );
};

export default withRouter(RegistrationPage);
