import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import App from './App';
import GlobalStyle from './globalStyles';

import common_zh from './translations/zh-HK/common.json';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'zh-HK',
  resources: {
    'zh-HK': {
      common: common_zh,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <I18nextProvider i18n={i18next}>
      <Router>
        <App />
      </Router>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
