import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const NoSelect = css`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ExpandableWrapper = styled.div<{
  expanded: boolean;
}>`
  display: ${(props) => (props.expanded ? 'block' : 'none')};
  position: relative;
`;

export const Spacer = styled.div<{
  height: number;
}>`
  height: ${(props) => props.height}px;
`;
