import styled from 'styled-components';

import { StyledButton } from '../../components/common';
import { NoSelect } from '../../styles';

export interface IStepTabProps {
  selected: boolean;
}

export const Wrapper = styled.div``;

export const StepTabWrapper = styled.div`
  background: #f6f6f6;
  display: flex;
  height: 124px;
  align-items: stretch;
  justify-content: center;
  padding: 0 22px;

  ${NoSelect}
`;

export const StepTab = styled.div<IStepTabProps>`
  background: ${(props) => (props.selected ? '#ffffff' : '#f6f6f6')};
  box-shadow: ${(props) => (props.selected ? '0px -2px 3px rgba(0, 0, 0, 0.25)' : 'none')};
  color: #008141;
  display: flex;
  align-self: flex-end;
  flex-basis: 50%;
  justify-content: center;
  height: calc(100% - 24px);
  padding: 0 8px;
`;

export const StepIcon = styled.img`
  width: 43px;
  height: 43px;
  align-self: center;
  margin-right: 14px;
`;

export const StepLabelWrapper = styled.div`
  color: #008141;
  align-self: center;
`;

export const StepLabel = styled.p`
  color: #008141;
  font-weight: bold;
`;

export const StepDesc = styled.p`
  color: #828282;
`;

export const ShareWrapper = styled.div`
  background: #f6f6f6;
  margin-top: 34px;
  padding: 30px 20px 36px 20px;
`;

export const SubTitle = styled.div`
  color: #4f4f4f;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
`;

export const ShareDesc = styled.div`
  color: #4f4f4f;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  white-space: pre-line;
  margin-top: 12px;
`;

export const ShareLink = styled.div`
  background: #ffffff;
  color: #008141;
  font-size: 22px;
  line-height: 30px;
  margin-top: 18px;
  padding: 26px 0;
  text-align: center;
`;

export const CopyLinkButton = styled.button`
  ${StyledButton};
  margin-top: 24px;
`;

export const ShareIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
`;

export const customStyles = {
  content: {
    top: '10px',
    left: '10px',
    right: '10px',
    bottom: '10px',
    padding: '0',
    overflow: 'hidden',
  },
};
