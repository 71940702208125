import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import AboutProduct from '../../components/about/AboutProduct';
import AboutReferral from '../../components/about/AboutReferral';
import { RegisterButton } from '../../components/common';
import SocialMedia from '../../components/SocialMedia';
import { Spacer } from '../../styles';

const Wrapper = styled.div`
  padding-top: 30px;

  h1 {
    margin: 0 20px;
  }
`;

const Description = styled.p`
  color: #828282;
  font-size: 22px;
  line-height: 140%;
  margin: 12px 20px 0 20px;
`;

const BenefitWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;

  img {
    display: block;
    width: 60%;
  }

  p {
    color: #828282;
    font-size: 18px;
    line-height: 160%;
    margin-top: 16px;
    text-align: center;
    white-space: pre-line;
  }
`;

const ArrowDown = styled.img`
  margin-top: 45px;
  height: 34px;
  width: 34px;
`;

const RegisterDesc = styled.div`
  color: #828282;
  font-size: 18px;
  line-height: 160%;
  margin: -40px 20px 0 20px;
`;

const Remark = styled.div`
  color: #828282;
  font-size: 12px;
  line-height: 160%;
  margin: 18px 20px;
`;

interface Section {
  image: string;
  desc: string;
}

const sectionImages = [
  '/gift_welcome.png',
  '/gift_birthday.png',
  '/gift_others.png',
];

const BenefitsPage: React.FC = () => {
  const { t } = useTranslation('common');

  const sections = useMemo<Section[]>(() => {
    const translation: string[] = t('benefits.sections', {
      returnObjects: true,
    });
    return translation.map<Section>((s, index) => ({
      image: sectionImages[index],
      desc: s,
    }));
  }, [t]);

  return (
    <Wrapper>
      <h1>{t('benefits.title')}</h1>
      <Description>{t('benefits.desc')}</Description>

      {sections.map((section, index) => (
        <BenefitWrapper key={uuidv4()}>
          <img src={section.image} alt="" />
          <p>{section.desc}</p>

          {index !== sections.length - 1 && (
            <ArrowDown src="/down_arrow.svg" />
          )}
        </BenefitWrapper>
      ))}

      <Link to="/">
        <RegisterButton>{t('buttons.register')}</RegisterButton>
      </Link>
      <RegisterDesc>{t('benefits.register')}</RegisterDesc>
      <Remark>{t('benefits.remark')}</Remark>

      <AboutReferral />
      <Spacer height={12} />
      <AboutProduct />
      <SocialMedia />
    </Wrapper>
  );
};

export default BenefitsPage;
