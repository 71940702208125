import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ResponsiveContainer } from './components/common';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

import RegistrationPage from './pages/registration';
import ThankyouPage from './pages/thankyou';
import ProductPage from './pages/product';
import SlideMenu from './components/SlideMenu';
import BenefitsPage from './pages/benefits';
import ReferralPage from './pages/referral';
import FacebookPage from './pages/facebook';

import Floodlight from './services/floodlight';
import GA from './services/ga';

const App: React.FunctionComponent = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const isRedirect = pathname === '/facebook';

  const handleShowMenu = (isShow: boolean) => {
    setShowMenu(isShow);
  };

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'auto';
  }, [showMenu]);

  useEffect(() => {
    Floodlight.tagPages();
    GA.trackPageView(location.pathname);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>保麗淨會員登記</title>
        <link rel="apple-touch-icon" href="/og_image_v3.jpg" />
        <meta
          name="description"
          content="登記成為會員 - 賞你會員禮遇及假牙護理資訊"
        />
        <meta name="og:image" content="/og_image_v3.jpg" />
      </Helmet>
      {showMenu && <SlideMenu onShowMenu={handleShowMenu} />}
      <ResponsiveContainer>
        {!isRedirect && <Header onShowMenu={handleShowMenu} />}
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={RegistrationPage} />
          {/* disable cs to fill in the form */}
          {/* <Route exact path="/signup" component={RegistrationPage} /> */}
          <Route exact path="/thankyou" component={ThankyouPage} />
          <Route exact path="/product" component={ProductPage} />
          <Route exact path="/benefits" component={BenefitsPage} />
          <Route exact path="/referral" component={ReferralPage} />

          {/* for redirects */}
          <Route exact path="/facebook" component={FacebookPage} />

          <Route render={() => <Redirect to="/" />} />
        </Switch>
        {!isRedirect && <Footer />}
      </ResponsiveContainer>

      <ToastContainer position="bottom-center" autoClose={5000} />
    </>
  );
};

export default App;
