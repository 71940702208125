import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const Wrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  height: calc(100% - 70px);
  overflow-y: auto;

  scrollbar-color: #0A9306 transparent;

  ::-webkit-scrollbar-thumb {
    background: #0A9306;
  }
`;

const CloseButton = styled.img`
  align-self: flex-end;
  display: block;
  position: absolute;
  right: 20px;
  top: 14px;
  height: 24px;
  width: 24px;

  cursor: pointer;
`;

const Title = styled.div`
  color: #008141;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 34px;
  padding: 0 20px;
`;

const SectionWrapper = styled.div`
  display: flex;
  color: #828282;
  flex: 0 0 auto;
  font-size: 18px;
  line-height: 170%;
  padding-left: 16px;
  padding-right: 30px;
`;

const SectionIndex = styled.div``;

const Section = styled.div`
  margin-left: 8px;
  white-space: pre-line;
`;

interface ModalProps {
  onClose: () => void;
}

const PrivacyModal: React.FC<ModalProps> = ({ onClose }: ModalProps) => {
  const { t } = useTranslation('common');

  const sections = useMemo<string[]>(
    () => t('terms.sections', { returnObjects: true }),
    [t],
  );

  return (
    <>
      <CloseButton src="/logo_close_2.svg" onClick={onClose} />
      <Wrapper>
        <Title>{t('terms.title')}</Title>
        {sections.map((section, index) => (
          <SectionWrapper key={uuidv4()}>
            <SectionIndex>{`${index + 1}.`}</SectionIndex>
            <Section>{section}</Section>
          </SectionWrapper>
        ))}
      </Wrapper>
    </>
  );
};

export default PrivacyModal;
