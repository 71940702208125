/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import axios, { AxiosRequestConfig } from 'axios';
import React, { useCallback, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import {
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import { IReferralForm } from '../../../../shared/interfaces';
import PrivacyModal from '../../modals/PrivacyModal';

import AboutProduct from '../../components/about/AboutProduct';
import Banner from '../../components/Banner';
import DescriptionComponent from '../../components/DescriptionComponent';
import ShareIcon from '../../components/ShareIcon';
import SocialMedia from '../../components/SocialMedia';
import { Spacer } from '../../styles';

import ReferralForm from './Form';
import TutorialSection from './Tutorial';
import {
  Wrapper,
  StepTabWrapper,
  StepTab,
  StepIcon,
  StepLabelWrapper,
  StepLabel,
  StepDesc,
  ShareWrapper,
  SubTitle,
  ShareLink,
  CopyLinkButton,
  ShareDesc,
  ShareIconsWrapper,
  customStyles,
} from './styled';

import GA from '../../services/ga';
import { copyToClipboard } from '../../utils';

enum Step {
  CREATE_LINK,
  SHARE_LINK,
}

const FB_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID as string;
const HOST = process.env.REACT_APP_HOST as string;

const ReferralPage: React.FC = () => {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('common');

  const [step, setStep] = useState(Step.CREATE_LINK);
  const [referralLink, setReferralLink] = useState<string>();

  const handleSubmit = async (formValues: IReferralForm) => {
    const { phone } = formValues;

    // order formValues
    const payload = {
      phone,
    };
    const config: AxiosRequestConfig = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL as string}/referral`,
      data: payload,
      withCredentials: false,
    };

    try {
      const response = await axios(config);
      const linkData = response?.data?.data;

      setReferralLink(linkData);

      window.scrollTo(0, 0);
      setStep(Step.SHARE_LINK);
      GA.trackEvent('click', 'submit', 'referral');
    } catch (e) {
      console.error(e);
      GA.trackEvent(
        'error',
        'submit',
        'referral',
        JSON.stringify(payload),
      );
    }
  };

  const handleShowPrivacy = (isShow: boolean) => {
    setShowPrivacy(isShow);

    if (!isShow && location.hash.includes('privacy')) {
      history.replace('/');
    }

    if (isShow) {
      GA.trackEvent('click', 'open', 'tnc');
    }
  };

  const handleCopyLink = useCallback(async () => {
    if (!referralLink) {
      toast.error(t('errors.unknown'));
      return;
    }

    try {
      copyToClipboard(referralLink);

      if (!toast.isActive(referralLink)) {
        toast.success(t('referral.share.copyLink.success'), {
          toastId: referralLink,
        });

        GA.trackEvent('click', 'referral', 'copy_link');
      }
    } catch (e) {
      toast.error(`${t('errors.unknown')}`);
    }
  }, [referralLink]);

  const handleShare = useCallback(
    async (type: string) => {
      let msg = t('referral.share.message');

      switch (type) {
        case 'wts':
          msg = `${msg}%0a${referralLink}`;
          if (isIOS) {
            window.location.href = `https://api.whatsapp.com/send?text=${msg}`;
          } else {
            window.open(`https://api.whatsapp.com/send?text=${msg}`);
          }
          GA.trackEvent('click', 'referral', 'share_whatsapp');
          break;
        case 'fb':
          window.open(
            `https://www.facebook.com/dialog/share?app_id=${FB_APP_ID}&display=popup&href=${referralLink}&redirect_uri=${HOST}`,
            'WindowName',
            'popup,width=400,height=300',
          );
          GA.trackEvent('click', 'referral', 'share_facebook');
          break;
        default:
          if (navigator.share) {
            navigator.share({
              text: `${t('referral.share.message')}`,
              url: referralLink,
            });
            GA.trackEvent('click', 'referral', 'share_others');
          }
      }
    },
    [referralLink],
  );

  return (
    <>
      <Helmet>
        <title>保麗淨會員推薦計劃</title>
        <link rel="apple-touch-icon" href="/n_og_referral_image.jpg" />
        <meta
          name="description"
          content="立即透過以下推薦連結登記成為保麗淨會員，成功登記，即享會員禮遇及假牙護理資訊！"
        />
        <meta name="og:image" content="/n_og_referral_image.jpg" />
      </Helmet>
      <Wrapper>
        {step === Step.CREATE_LINK && (
          <>
            <Banner src="/banner_referral.jpg" />
            <DescriptionComponent
              title="referral.header"
              desc="referral.desc"
            />
            <div style={{ marginTop: '20px' }} />
          </>
        )}

        <StepTabWrapper>
          <StepTab selected={step === Step.CREATE_LINK}>
            <StepIcon src="logo_link.png" />
            <StepLabelWrapper>
              <StepLabel>{t('referral.step.creation.label')}</StepLabel>
              <StepDesc>{t('referral.step.creation.desc')}</StepDesc>
            </StepLabelWrapper>
          </StepTab>
          <StepTab selected={step === Step.SHARE_LINK}>
            <StepIcon src="logo_share.png" />
            <StepLabelWrapper>
              <StepLabel>{t('referral.step.share.label')}</StepLabel>
              <StepDesc>{t('referral.step.share.desc')}</StepDesc>
            </StepLabelWrapper>
          </StepTab>
        </StepTabWrapper>

        <Spacer height={32} />

        {step === Step.CREATE_LINK && (
          <>
            <DescriptionComponent
              title="referral.subHeader"
              desc="referral.subDesc"
            />

            <ReferralForm
              onSubmit={handleSubmit}
              onShowPrivacy={handleShowPrivacy}
            />
          </>
        )}

        {step === Step.SHARE_LINK && (
          <>
            <DescriptionComponent
              title="referral.createdLinkHeader"
              desc="referral.createdLinkDesc"
            />

            <ShareWrapper>
              <SubTitle>{t('referral.share.title')}</SubTitle>
              <ShareLink>{referralLink}</ShareLink>
              <CopyLinkButton onClick={handleCopyLink}>
                {t('referral.share.copyLink.label')}
              </CopyLinkButton>
              <ShareDesc>
                {t(
                  `referral.share.desc.${isMobile ? 'mobile' : 'desktop'}`,
                )}
              </ShareDesc>
              <ShareIconsWrapper>
                <ShareIcon
                  src="logo_share_wts.png"
                  onClick={() => handleShare('wts')}
                />
                <ShareIcon
                  src="logo_share_fb.png"
                  onClick={() => handleShare('fb')}
                />
                {isMobile && (
                  <ShareIcon
                    src="logo_share_others.png"
                    onClick={() => handleShare('others')}
                  />
                )}
              </ShareIconsWrapper>
            </ShareWrapper>
          </>
        )}

        {step === Step.SHARE_LINK && (
          <TutorialSection referralLink={referralLink || ''} />
        )}

        <Modal
          isOpen={showPrivacy}
          style={customStyles}
          ariaHideApp={false}
        >
          <PrivacyModal onClose={() => handleShowPrivacy(false)} />
        </Modal>

        <AboutProduct />
        <SocialMedia />
      </Wrapper>
    </>
  );
};

export default withRouter(ReferralPage);
