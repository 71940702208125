import React, { useMemo } from 'react';

const useFormOptions = (t: any) => {
  const ageOptions = useMemo<string[]>(
    () => t('register.form.options.age', { returnObjects: true }),
    [t],
  );

  const genderOptions = useMemo<string[]>(
    () => t('register.form.options.gender', { returnObjects: true }),
    [t],
  );

  const birthdayMonthOptions = useMemo<string[]>(
    () => t('register.form.options.birthdayMonth', {
      returnObjects: true,
    }),
    [t],
  );

  const denturePositionOptions = useMemo<string[]>(
    () => t('register.form.options.denturePosition', {
      returnObjects: true,
    }),
    [t],
  );

  const dentureTypeOptions = useMemo<string[]>(
    () => t('register.form.options.dentureType', {
      returnObjects: true,
    }),
    [t],
  );

  const periodOptions = useMemo<string[]>(
    () => t('register.form.options.period', { returnObjects: true }),
    [t],
  );

  const isUserOptions = useMemo<string[]>(
    () => t('register.form.options.isUser', { returnObjects: true }),
    [t],
  );

  const sourceOptions = useMemo<string[]>(
    () => t('register.form.options.source', { returnObjects: true }),
    [t],
  );

  return {
    genderOptions,
    ageOptions,
    birthdayMonthOptions,
    denturePositionOptions,
    dentureTypeOptions,
    periodOptions,
    isUserOptions,
    sourceOptions,
  };
};

export default useFormOptions;
