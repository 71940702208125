import React from 'react';
import styled from 'styled-components';
import { StyledButton } from './common';

interface IShareIconProps {
  src: string;
  onClick: (event) => void;
}

const Wrapper = styled.button`
  ${StyledButton};
  height: 112px;
  margin: 8px 4px 0 4px;
  padding: 0;
  text-align: center;
  max-width: 120px;

  img {
    width: 70px;
  }
`;

const ShareIcon: React.FC<IShareIconProps> = ({
  src,
  onClick,
}: IShareIconProps) => (
  <Wrapper onClick={onClick}>
    <img src={src} alt="" />
  </Wrapper>
);

export default ShareIcon;
