import styled from 'styled-components';
import { StyledButton } from '../common';

const SubmitButton = styled.input`
  ${StyledButton};
  margin: 26px 20px 0 20px;
  width: calc(100% - 40px);

  :disabled {
    filter: grayscale(100%);
  }
`;

export default SubmitButton;
