import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
`;

const Title = styled.div`
  color: #008141;
  font-size: 34px;
  font-weight: bold;
  line-height: 40px;
`;

const Remark = styled.div`
  color: red;
  font-size: 18px;
  line-height: 40px;
`;

const Description = styled.div`
  color: #828282;
  font-size: 22px;
  margin-top: 8px;
  line-height: 140%;
`;

interface DescriptionProps {
  title: string;
  desc?: string;
  remark?: string;
}

const DescriptionComponent: React.FC<DescriptionProps> = ({
  title,
  desc,
  remark,
}: DescriptionProps) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t(title)}</Title>
        {remark && <Remark>{t(remark)}</Remark>}
      </TitleWrapper>
      {desc && <Description>{t(desc)}</Description>}
    </Wrapper>
  );
};

DescriptionComponent.defaultProps = {
  desc: undefined,
  remark: undefined,
};

export default DescriptionComponent;
