import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { IReferralForm } from '../../../../shared/interfaces';
import DisclaimerWrapper from '../../components/form/DisclaimerWrapper';
import FormCheckbox from '../../components/form/FormCheckbox';
import FormErrorLabel from '../../components/form/FormErrorLabel';
import FormInput from '../../components/form/FormInput';
import FormLabel from '../../components/form/FormLabel';
import SubmitButton from '../../components/form/SubmitButton';
import { validateForm } from '../../utils';

interface FormProps {
  onSubmit: (formValues: IReferralForm) => void;
  onShowPrivacy: (isShow: boolean) => void;
}

const Wrapper = styled.div`
  background: #f6f6f6;
  padding: 40px 20px;
`;

const Form = styled.form`
  margin-top: 30px;
  margin-bottom: 52px;
`;

const TermsWrapper = styled.div`
  margin-top: 14px;

  span {
    line-height: 32px;
  }
`;

const TermsSectionsWrapper = styled.div`
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  height: 175px;
  margin-bottom: 26px;
  overflow-y: auto;
`;

const TermsSectionWrapper = styled.div`
  display: flex;
  color: #828282;
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 24px;
  padding-left: 12px;
  padding-right: 16px;
`;

const SectionIndex = styled.div``;

const Section = styled.div`
  margin-left: 24px;
  white-space: pre-line;
`;

const ReferralForm: React.FC<FormProps> = ({
  onSubmit,
  onShowPrivacy,
}) => {
  const { t } = useTranslation('common');
  const inputRefs = {
    phoneRef: useRef(null),
  };

  const [errors, setErrors] = useState(
    {} as { [key: string]: boolean },
  );
  const [submitted, setSubmitted] = useState(false);
  const [formValues, setFormValues] = useState<IReferralForm>({
    phone: '',
    privacyPolicy: false,
  });

  const termsSections = useMemo<string[]>(
    () => t('referral.form.terms.sections', { returnObjects: true }),
    [t],
  );

  const handleValidate = () => {
    const e: any = {};
    Object.keys(formValues).forEach((key) => {
      e[key] = validateForm(key, formValues[key]);
    });

    setErrors(e);
    return e;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const e = handleValidate();

    const firstError = Object.keys(e)
      .filter((key) => e[key] === true)
      ?.shift();

    if (firstError) {
      if (firstError === 'privacyPolicy') {
        return;
      }

      const ref = inputRefs[`${firstError}Ref`];
      window.scrollTo(0, ref.current.offsetTop - 40);
      return;
    }

    setSubmitted(true);
    // parse to parent
    onSubmit(formValues);
  };

  const handleChange = (event) => {
    const { id, value, checked } = event.target;
    console.log(id, value, checked);

    let fValue = value.trim();
    switch (id) {
      case 'privacyPolicy':
        fValue = checked;
        break;
      default:
        event.preventDefault();
    }

    setFormValues({
      ...formValues,
      [id]: fValue,
    });

    setErrors((prev) => {
      const e = {
        ...prev,
      };
      e[id] = false;
      return e;
    });
  };

  const handleShowPrivacy = (event) => {
    event.preventDefault();
    onShowPrivacy(true);
  };

  return (
    <Form action="#" onSubmit={handleSubmit}>
      <Wrapper>
        <FormLabel>
          {t('referral.form.phone')}
          <FormInput
            id="phone"
            name="phone"
            type="tel"
            className={errors.phone ? 'error' : ''}
            ref={inputRefs.phoneRef}
            maxLength={Number('8')}
            onBlur={handleChange}
          />
        </FormLabel>
        {errors.phone && (
          <FormErrorLabel>
            {formValues.phone === ''
              ? t('errors.empty')
              : t('errors.invalidPhone')}
          </FormErrorLabel>
        )}
      </Wrapper>

      <DisclaimerWrapper>
        <div>
          <span>{t('referral.form.disclaimer1')}</span>
          <a href="#!" onClick={handleShowPrivacy}>
            {t('referral.form.disclaimerClickable')}
          </a>
          <span>{t('referral.form.disclaimer2')}</span>
        </div>

        <TermsWrapper>
          <span>{t('referral.form.terms.title')}</span>
          <TermsSectionsWrapper>
            {termsSections.map((section, index) => (
              <TermsSectionWrapper key={uuidv4()}>
                <SectionIndex>{`${index + 1}.`}</SectionIndex>
                <Section>{section}</Section>
              </TermsSectionWrapper>
            ))}
          </TermsSectionsWrapper>
        </TermsWrapper>

        <FormLabel
          className={errors.privacyPolicy ? 'error' : ''}
        >
          <FormCheckbox
            id="privacyPolicy"
            name="privacyPolicy"
            type="checkbox"
            onChange={handleChange}
          />
          {t('referral.form.agreeDisclaimer')}
        </FormLabel>
        {errors.privacyPolicy && (
          <FormErrorLabel>{t('errors.empty')}</FormErrorLabel>
        )}
      </DisclaimerWrapper>

      <SubmitButton
        type="submit"
        disabled={submitted}
        value={t('referral.form.submit') as string}
      />
    </Form>
  );
};

export default ReferralForm;
