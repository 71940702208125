import styled, { css } from 'styled-components';

const Input = css`
  color: #4f4f4f;
  border-color: #bdbdbd;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: block;
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
  padding: 0 14px;
  height: 50px;
  width: calc(100% - 10px);
  outline: none !important;

  &.error {
    border-color: #f44336;
  }
`;

const FormSelect = styled.select`
  ${Input}

  &:focus {
    border-color: #719ece;
    border-width: 4px;
  }
`;

export default FormSelect;
