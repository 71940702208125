import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { validateForm } from '../../utils';

import { IRegisterForm } from '../../../../shared/interfaces';

import DisclaimerWrapper from '../../components/form/DisclaimerWrapper';
import FormCheckbox from '../../components/form/FormCheckbox';
import FormErrorLabel from '../../components/form/FormErrorLabel';
import FormLabel from '../../components/form/FormLabel';
import FormInput from '../../components/form/FormInput';
import FormSelect from '../../components/form/FormSelect';
import SubmitButton from '../../components/form/SubmitButton';

import useFormOptions from '../../hooks/formOption';

interface FormProps {
  onSubmit: (formValues: IRegisterForm) => void;
  onShowPrivacy: (isShow: boolean) => void;
}

const Wrapper = styled.div`
  background: #f6f6f6;
  padding: 40px 20px;
`;

const Form = styled.form`
  margin-top: 30px;
`;

const RegisterForm: React.FC<FormProps> = ({
  onSubmit,
  onShowPrivacy,
}: FormProps) => {
  const inputRefs = {
    nameRef: useRef(null),
    genderRef: useRef(null),
    phoneRef: useRef(null),
    ageRef: useRef(null),
    birthdayMonthRef: useRef(null),
    addressRef: useRef(null),
    denturePositionRef: useRef(null),
    dentureTypeRef: useRef(null),
    periodRef: useRef(null),
    isUserRef: useRef(null),
    sourceRef: useRef(null),
  };

  const { t } = useTranslation('common');
  const [errors, setErrors] = useState({} as { [key: string]: boolean });
  const [submitted, setSubmitted] = useState(false);
  const [formValues, setFormValues] = useState<IRegisterForm>({
    name: '',
    gender: '',
    phone: '',
    age: '',
    birthdayMonth: '',
    address: '',
    denturePosition: '',
    dentureType: '',
    period: '',
    isUser: '',
    source: '',
    privacyPolicy: false,
  });

  const {
    genderOptions,
    ageOptions,
    birthdayMonthOptions,
    denturePositionOptions,
    dentureTypeOptions,
    periodOptions,
    isUserOptions,
    sourceOptions,
  } = useFormOptions(t);

  const handleValidate = () => {
    const e: any = {};
    Object.keys(formValues).forEach((key) => {
      e[key] = validateForm(key, formValues[key]);
    });

    setErrors(e);
    return e;
  };

  useEffect(() => {
    console.log('errors', errors);
  }, [errors]);

  useEffect(() => {
    console.log('values', formValues);
  }, [formValues]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const e = handleValidate();

    const firstError = Object.keys(e)
      .filter((key) => e[key] === true)
      ?.shift();

    if (firstError) {
      if (firstError === 'privacyPolicy') {
        return;
      }

      const ref = inputRefs[`${firstError}Ref`];
      window.scrollTo(0, ref.current.offsetTop - 40);
      return;
    }

    setSubmitted(true);
    // parse to parent
    onSubmit(formValues);
  };

  const handleChange = (event) => {
    const { id, value, checked } = event.target;
    console.log(id, value, checked);

    let fValue = value.trim();
    switch (id) {
      case 'privacyPolicy':
        fValue = checked;
        break;
      default:
        event.preventDefault();
    }

    setFormValues({
      ...formValues,
      [id]: fValue,
    });

    setErrors((prev) => {
      const e = {
        ...prev,
      };
      e[id] = false;
      return e;
    });
  };

  const handleShowPrivacy = (event) => {
    event.preventDefault();
    onShowPrivacy(true);
  };

  return (
    <Form action="#" onSubmit={handleSubmit}>
      <Wrapper>
        <FormLabel>
          {t('register.form.name')}
          <FormInput
            id="name"
            name="name"
            className={errors.name ? 'error' : ''}
            ref={inputRefs.nameRef}
            onBlur={handleChange}
          />
        </FormLabel>
        {errors.name && (
          <FormErrorLabel>{t('errors.empty')}</FormErrorLabel>
        )}

        <FormLabel>
          {t('register.form.gender')}
          <FormSelect
            id="gender"
            name="gender"
            className={errors.gender ? 'error' : ''}
            ref={inputRefs.genderRef}
            value={formValues.gender}
            onChange={handleChange}
          >
            <option value="" disabled>
              {t('register.form.options.placeholder')}
            </option>
            {genderOptions.map((value) => (
              <option key={uuidv4()} value={value}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormLabel>
        {errors.gender && (
          <FormErrorLabel>{t('errors.empty')}</FormErrorLabel>
        )}

        <FormLabel>
          {t('register.form.phone')}
          <FormInput
            id="phone"
            name="phone"
            type="tel"
            className={errors.phone ? 'error' : ''}
            ref={inputRefs.phoneRef}
            maxLength={Number('8')}
            onBlur={handleChange}
          />
        </FormLabel>
        {errors.phone && (
          <FormErrorLabel>
            {formValues.phone === ''
              ? t('errors.empty')
              : t('errors.invalidPhone')}
          </FormErrorLabel>
        )}

        <FormLabel>
          {t('register.form.age')}
          <FormSelect
            id="age"
            name="age"
            className={errors.age ? 'error' : ''}
            ref={inputRefs.ageRef}
            value={formValues.age}
            onChange={handleChange}
          >
            <option value="" disabled>
              {t('register.form.options.placeholder')}
            </option>
            {ageOptions.map((value) => (
              <option key={uuidv4()} value={value}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormLabel>
        {errors.age && (
          <FormErrorLabel>{t('errors.empty')}</FormErrorLabel>
        )}

        <FormLabel>
          {t('register.form.birthdayMonth')}
          <FormSelect
            id="birthdayMonth"
            name="birthdayMonth"
            className={errors.birthdayMonth ? 'error' : ''}
            ref={inputRefs.birthdayMonthRef}
            value={formValues.birthdayMonth}
            onChange={handleChange}
          >
            <option value="" disabled>
              {t('register.form.options.placeholder')}
            </option>
            {birthdayMonthOptions.map((value) => (
              <option key={uuidv4()} value={value}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormLabel>
        {errors.birthdayMonth && (
          <FormErrorLabel>{t('errors.empty')}</FormErrorLabel>
        )}

        <FormLabel>
          {t('register.form.address')}
          <FormInput
            id="address"
            name="address"
            className={errors.address ? 'error' : ''}
            ref={inputRefs.addressRef}
            onBlur={handleChange}
          />
        </FormLabel>
        {errors.address && (
          <FormErrorLabel>
            {formValues.address === ''
              ? t('errors.empty')
              : t('errors.invalidAddress')}
          </FormErrorLabel>
        )}

        <FormLabel>
          {t('register.form.denturePosition')}
          <FormSelect
            id="denturePosition"
            name="denturePosition"
            className={errors.denturePosition ? 'error' : ''}
            ref={inputRefs.denturePositionRef}
            value={formValues.denturePosition}
            onChange={handleChange}
          >
            <option value="" disabled>
              {t('register.form.options.placeholder')}
            </option>
            {denturePositionOptions.map((value) => (
              <option key={uuidv4()} value={value}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormLabel>
        {errors.denturePosition && (
          <FormErrorLabel>{t('errors.empty')}</FormErrorLabel>
        )}

        <FormLabel>
          {t('register.form.dentureType')}
          <FormSelect
            id="dentureType"
            name="dentureType"
            className={errors.dentureType ? 'error' : ''}
            ref={inputRefs.dentureTypeRef}
            value={formValues.dentureType}
            onChange={handleChange}
          >
            <option value="" disabled>
              {t('register.form.options.placeholder')}
            </option>
            {dentureTypeOptions.map((value) => (
              <option key={uuidv4()} value={value}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormLabel>
        {errors.dentureType && (
          <FormErrorLabel>{t('errors.empty')}</FormErrorLabel>
        )}

        <FormLabel>
          {t('register.form.period')}
          <FormSelect
            id="period"
            name="period"
            className={errors.period ? 'error' : ''}
            ref={inputRefs.periodRef}
            value={formValues.period}
            onChange={handleChange}
          >
            <option value="" disabled>
              {t('register.form.options.placeholder')}
            </option>
            {periodOptions.map((value) => (
              <option key={uuidv4()} value={value}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormLabel>
        {errors.period && (
          <FormErrorLabel>{t('errors.empty')}</FormErrorLabel>
        )}

        <FormLabel>
          {t('register.form.isUser')}
          <FormSelect
            id="isUser"
            name="isUser"
            className={errors.isUser ? 'error' : ''}
            ref={inputRefs.isUserRef}
            value={formValues.isUser}
            onChange={handleChange}
          >
            <option value="" disabled>
              {t('register.form.options.placeholder')}
            </option>
            {isUserOptions.map((value) => (
              <option key={uuidv4()} value={value}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormLabel>
        {errors.isUser && (
          <FormErrorLabel>{t('errors.empty')}</FormErrorLabel>
        )}

        <FormLabel>
          {t('register.form.source')}
          <FormSelect
            id="source"
            name="source"
            className={errors.source ? 'error' : ''}
            ref={inputRefs.sourceRef}
            value={formValues.source}
            onChange={handleChange}
          >
            <option value="" disabled>
              {t('register.form.options.placeholder')}
            </option>
            {sourceOptions.map((value) => (
              <option key={uuidv4()} value={value}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormLabel>
        {errors.source && (
          <FormErrorLabel>{t('errors.empty')}</FormErrorLabel>
        )}
      </Wrapper>

      <DisclaimerWrapper>
        <div>
          <span>{t('register.form.disclaimer1')}</span>
          <a href="#!" onClick={handleShowPrivacy}>
            {t('register.form.disclaimerClickable')}
          </a>
          <span>{t('register.form.disclaimer2')}</span>
        </div>

        <FormLabel className={errors.privacyPolicy ? 'error' : ''}>
          <FormCheckbox
            id="privacyPolicy"
            name="privacyPolicy"
            type="checkbox"
            onChange={handleChange}
          />
          {t('register.form.agreeDisclaimer')}
        </FormLabel>
        {errors.privacyPolicy && (
          <FormErrorLabel>{t('errors.empty')}</FormErrorLabel>
        )}
      </DisclaimerWrapper>

      <SubmitButton
        type="submit"
        disabled={submitted}
        value={t('register.form.submit') as string}
      />
    </Form>
  );
};

export default RegisterForm;
