import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import AboutBenefit from '../../components/about/AboutBenefit';
import AboutProduct from '../../components/about/AboutProduct';
import AboutReferral from '../../components/about/AboutReferral';
import DescriptionComponent from '../../components/DescriptionComponent';
import SocialMedia from '../../components/SocialMedia';
import { Spacer } from '../../styles';

import Floodlight from '../../services/floodlight';

const Wrapper = styled.div`
  padding-top: 30px;
`;

const ThankyouPage: React.FC = () => {
  useEffect(() => {
    Floodlight.tagThankyou();
  }, []);

  return (
    <Wrapper>
      <DescriptionComponent
        title="thankyou.title"
        desc="thankyou.content"
      />

      <Spacer height={38} />
      <AboutReferral />
      <Spacer height={12} />
      <AboutBenefit />
      <Spacer height={12} />
      <AboutProduct />

      <SocialMedia />
    </Wrapper>
  );
};

export default withRouter(ThankyouPage);
