export const isValidName = (value: string): boolean => value.match(/^[\u4e00-\u9fa5]{2,5}$|^[a-zA-Z][a-zA-Z ]+$/gm)
  ?.index === 0;

export const isValidPhone = (value: string): boolean => value.match(/^[456789]\d{7}$/)?.index === 0;

export const validateForm = (
  key: string,
  value: string | boolean,
): boolean => {
  switch (key) {
    case 'phone':
      return value === '' || !isValidPhone(value as string);
    case 'address':
      return value === '' || (value as string).includes('@');
    case 'privacyPolicy':
      return value !== true;
    default:
      // skip
      return value === '';
  }
};

export const copyToClipboard = (
  textToCopy: string,
): Promise<void> => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  }

  // text area method
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  // make the textarea out of viewport
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((res, rej) => {
    // here the magic happens
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    document.execCommand('copy') ? res() : rej();
    textArea.remove();
  });
};
