import React from 'react';
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import GA from '../services/ga';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 28px 0;

  a {
    display: block;
    width: 70%;
    max-width: 300px;
  }
`;

const MediaWrapper = styled.div`
  display: flex;
  height: 40px;

  :not(:first-child) {
    width: 70%;
    max-width: 300px;
    margin-top: 12px;
  }
`;

const IconWrapper = styled.div<IconWrapperProps>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
`;

const MediaContent = styled.div`
  border: 1px solid #c0c0c0;
  color: #989898;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  line-height: 170%;
  padding-right: 16px;
  text-align: right;
  width: calc(100% - 44px);

  p {
    margin: 0;
  }

  img {
    margin-left: 12px;
    height: 12px;
    width: auto;
  }
`;

interface IconWrapperProps {
  backgroundColor: string;
}

const SocialMedia: React.FC = () => {
  const { t } = useTranslation('common');

  const handleWhatsapp = () => {
    GA.trackEvent('click', 'share', 'whatsapp');

    const url = process.env.REACT_APP_WHATSAPP_URL as string;
    if (isIOS) {
      window.location.href = url;
    } else {
      window.open(url);
    }
  };

  return (
    <Wrapper>
      <Link to="/facebook" target="_blank">
        <MediaWrapper>
          <IconWrapper backgroundColor="#1778F2">
            <img src="/logo_fb.svg" alt="" />
          </IconWrapper>
          <MediaContent>
            <p>{t('social.facebook')}</p>
            <img src="/arrow.svg" alt="" />
          </MediaContent>
        </MediaWrapper>
      </Link>

      <MediaWrapper onClick={handleWhatsapp}>
        <IconWrapper backgroundColor="#00e676">
          <img src="/logo_wts.svg" alt="" />
        </IconWrapper>
        <MediaContent>
          <p>{t('social.whatsapp')}</p>
          <img src="/arrow.svg" alt="" />
        </MediaContent>
      </MediaWrapper>
    </Wrapper>
  );
};

export default SocialMedia;
